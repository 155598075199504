import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow, MDBTextArea, MDBTooltip } from 'mdb-react-ui-kit';

import DataTableCustom from '../general/DataTableCustom';
import HeaderContainer from '../general/HeaderContainer';
import { TableColumn } from 'react-data-table-component';

import { formatMoney, getCategorias, getColorTransaccion, getCosto, validateRequiredData } from '../general/utils';
import { StyledContainerConcepto } from "../general/StylesUtils";
import AddItemModal from '../settings/AddItemModal';
import SelectCustom from '../general/SelectCustom';
import { optionsTransacciones } from '../general/ciudades';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { shallow } from 'zustand/shallow';
import { ITransaccion, useSettingsStore } from '../../store/settingsStore';
import moment from 'moment';
import { transaccionDelete, transaccionSave } from '../settings/api';
import { ButtonProps } from 'mdb-react-ui-kit/dist/types/free/components/Button/types';
import SearchContainer from '../general/SearchContainer';
import styled from '@emotion/styled';
import BancosSaldos from './BancosSaldos';
import ConfirmDeleteModal from '../settings/ConfirmDeleteModal';
import AddImportarForm from './AddImportarForm';
import { updateProyectoEgreso, updateProyectoIngreso } from './saveUtilis';
import NumericFormatCustom from '../general/NumericFormatCustom';
import { NumericFormat } from 'react-number-format';

const StyledMDBCol = styled(MDBCol)`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper[data-placement^=bottom] {
    position: fixed !important;
    top: 115px !important;
    left: 200px !important;
    padding-top: 0px !important;
    transform: translate(411px, 75px) !important;
  }
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    transform: translate(50px, 0px) !important;
  }
`
const StyledSection = styled.div`
  position: relative;
`;
const StyledToolTip = styled.div`
  > a {
    color: black !important;
    font-weight: 600;
    max-width: 110px;
    display: block;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
}

interface IDataExcelItem {
  descripcion: string;
  categoria: string;
  subCategoria: string;
  banco: string;
  monto: string;
  fechaDeCambio: string;
  fechaDeInicio: string;
  transaccion: string;
}

type DataRow = {
  descripcion: string;
  monto: string;
  transaccion: string;
  categoriaLabel: string;
  subcategoriaLabel: string;
  bancoLabel: string;
  updatedAt: number;
  fecha: number;
};

const AddNewFormTransferencia = ({showModal = false, setShowModal, typeAction, handleSave }: IProps) => {
  const optionsBancos = useSettingsStore((state) => (state.bancosSelect), shallow)
  const [saving, setSaving] = useState(false)
  const [alert, setAlert] = useState(null)
  const [isValidateFine, setIsValidateFine] = useState(false)
  const [formValue, setFormValue] = useState({
    uid: null,
    descripcion: '-',
    fecha: new Date(),
    bancoOrigen: '',
    banco: '',
    monto: '',
    comentario: '',
    bancoOrigenLabel: "",
    bancoLabel: "",
  })

  const [_, setRequiredStatus] = useState({
    // descripcion: false,
    fecha: true,
    bancoOrigen: false,
    banco: false,
    monto: false,
  })

  const showAlert = (data: any) => {
    setAlert(data)

    if (data?.type === "success") {
      setTimeout(() => {
        setAlert(null)
        setShowModal(!showModal)
        handleSave()
      },1500 )
    }
  }

  const handleSaveData = async() => {
    // if (!isMoneda(formValue.monto)) {
    //   console.log("format > No es un formato - ", formValue.monto)
    //   return 
    // }
    try {
      setSaving(true)
      const { uid, banco, fecha, bancoOrigen } = formValue
      const transaccion = "transferencia"

      if (uid ) { // on edit mode > it's need to delete
        const {transaccion: transaccionOrg, proyecto: proyectoOrg, banco: bancoOrg, bancoOrigen: bancoOrigenOrg, monto: montoOrg } = typeAction.data
        const payloadDelete = {
          transaccion: transaccionOrg,
          proyecto: proyectoOrg,
          banco: bancoOrg,
          bancoOrigen: bancoOrigenOrg,
          monto: montoOrg,
          modo: "delete", //"edit",
        }
        console.log("DELETE > dataOrg > ", payloadDelete)
        await transaccionDelete(payloadDelete, uid)
      }

      const payload = {
        type: transaccion, // ingreso | egreso
        name: banco,
        nameOrigen: bancoOrigen,
        ...{ uid },
        detalles: { ...formValue, transaccion, fecha: moment(fecha).format("X") },
      }
console.log("payload > ", payload)
      await transaccionSave(payload)

      showAlert({
         title: "Mensaje", message: "Datos Guardados...", type: "success",
      })
    } catch (e) {
      console.log("error > ", e)
      setSaving(false)
    }
  }

  useEffect(() => {
    if (!typeAction?.data) {
      return;
    }

  const {fecha} = typeAction.data

    setFormValue({
      ...typeAction.data,
      fecha: new Date(parseInt(fecha)*1000),
    })
    setIsValidateFine(true)
    setRequiredStatus({
      fecha: true,
      bancoOrigen: true,
      banco: true,
      monto: true,
    })
  }, [typeAction.data])

  const onChange = (e: any) => {
    const { name, value } = e.target

    setFormValue({ ...formValue, [name]: value });
  }

  const onChangeFormatNumber = (e: any, name: string) => {
    const { value } = e

    setFormValue({ ...formValue, [name]: value });
  }

  const isValidate = (e: any) => {
    validateRequiredData(e, setRequiredStatus, setIsValidateFine)
  }

  const onChangeSelect = (data: any) => {
    const {required, name, value, label} = data
    console.log("onChangeSelect-data > ", data)

    isValidate({ target: { name, value, required }})

    const descripcion = (name === "bancoOrigen")  
      ? `T cuenta '${label}' a '${formValue.bancoLabel}'`
      : `T cuenta '${formValue.bancoOrigenLabel}' a '${label}'`

    setFormValue({ ...formValue, descripcion, [name]: value, [`${name}Label`]: label });
  }

  const setStartDate = (date: any) => {
    setFormValue({...formValue, fecha: date})
  }

  const constentForm = () => {
    const { descripcion, fecha, bancoOrigen, banco, monto } = formValue

    return (
      <StyledContainerConcepto className="row" style={{padding: "20px"}} isValidated>
        <MDBRow className='mb-3'>
          <MDBCol md='6' className="px-2">
            {/* <MDBInput label="Monto" type='text' name='monto' value={monto} onChange={onChange} onBlur={isValidate} required /> */}
            <NumericFormatCustom 
              name="monto"
              required={true}
              value={monto} 
              handleOnBlur={isValidate}
              handleOnChange={onChangeFormatNumber}
            />
          </MDBCol>
          <StyledMDBCol md='6'>
            <DatePicker 
            className="form-control active" 
            selected={fecha} 
            onChange={setStartDate}  
            onBlur={isValidate}  
            customInput={
              <input
                readOnly
                onClick={(e) => e.preventDefault() }
                placeholder="Selecciona una fecha"
              />
            }
            />
          </StyledMDBCol>
          <MDBCol md='12' className="px-2 mb-4">
            <MDBInput label="Descripcion" type='text' name='descripcion' value={descripcion} onChange={onChange} disabled />
          </MDBCol>
          <MDBCol md='6' className="px-2">
            <SelectCustom options={optionsBancos} onChangeSelect={onChangeSelect} name='bancoOrigen' defaultValue={bancoOrigen} required />
          </MDBCol>
          <MDBCol md='6' className="px-2">
            <SelectCustom options={optionsBancos} onChangeSelect={onChangeSelect} name='banco' defaultValue={banco} required />
          </MDBCol>


        </MDBRow>
        <MDBRow className='mt-1 px-2'>
            <MDBTextArea 
              label='Comentarios' 
              name='comentario' 
              value={formValue.comentario} 
              onChange={onChange} rows={3}
              />
          </MDBRow>        
      </StyledContainerConcepto>
    )
  }

  return (
    <AddItemModal
      keyModal="modal-lineadenegocio"
      alert={alert}
      typeAction={typeAction}
      showModal={showModal}
      setShowModal={setShowModal}
      handleSave={handleSaveData}
      disabledSaveButton={!isValidateFine}
      saving={saving}
    >
      { constentForm() }
    </AddItemModal>
  )
}

const startInformation = { subcategoria: "", subcategoriaLabel: "", proyecto: "", proyectoLabel: "", rubro: "", rubroLabel: "", monto: "" }

const getInitialData = (data: any) => {
  const formValue = {
    uid: null,
    descripcion: '',
    proyecto: '',
    categoria: '',
    categoriaLabel: "",
    subcategoria: '',
    subcategoriaLabel: "",
    transaccion: '',
    fecha: new Date(),
    monto: '',
    comentario: '',
    banco: '',
    bancoLabel: "",
    rubro: "",
    rubroLabel: "",
  }

  if (data?.uid) {
    const { uid = null, descripcion, proyecto, categoria, categoriaLabel, subcategoria, subcategoriaLabel, transaccion, fecha, monto, comentario, banco, bancoLabel, rubro, rubroLabel } = data

    const info = { 
      ...formValue, 
      uid,
      descripcion,
      proyecto,
      categoria,
      categoriaLabel,
      subcategoria,
      subcategoriaLabel,
      transaccion,
      fecha: new Date(parseInt(fecha)*1000),
      monto, 
      comentario, 
      banco,
      bancoLabel,
      rubro,
      rubroLabel,
    }
    
    return info
  }
  return formValue
}
const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave }: IProps) => {
  const proyectosServer = useSettingsStore((state) => (state.proyectos), shallow)
  const { getProyectosServer, getRequisicionesServer } = useSettingsStore()
  const optionsBancos = useSettingsStore((state) => (state.bancosSelect), shallow)
  const optionsCategoriasServer = useSettingsStore((state) => (state.categoriasSelect), shallow)
  const [saving, setSaving] = useState(false)
  const [alert, setAlert] = useState(null)
  const [isValidateFine, setIsValidateFine] = useState(false)
  const [optionsCategorias, setOptionsCategorias] = useState<any[]>([])
  const [optionsSubcategorias, setOptionsSubcategorias] = useState<any[]>([])
  const [proyectosGanados, setProyectosGanados] = useState<any[]>([])
  const [proyectosGanadosOptions, setProyectosGanadosOptions] = useState<any[]>([])
  const [rubrosOptions, setRubrosOptions] = useState<any[]>([])
  const [detallesInformation, setDetallesInformation] = useState(0)
  const [proyectoNow, setProyectoNow] = useState<any>(null)
  const [formValue, setFormValue] = useState(getInitialData(typeAction?.data))

  const [ , setRequiredStatus] = useState({
    descripcion: false,
    categoria: false,
    subcategoria: false,
    transaccion: false,
    fecha: true,
    banco: false,
    monto: false,
  })

  useEffect(() => {
    const getData = async() => {
      getProyectosServer()
      getRequisicionesServer()
    }
    
    getData()
  }, [getProyectosServer, getRequisicionesServer])

  // const getCurrentProyecto = useCallback((idProyecto: any) => {
  //   console.log("proyectosGanados >> ", proyectosGanados)
  //   const proyectoInfo = proyectosGanados.find(data => data.uid === idProyecto)
  //   setProyectoNow(proyectoInfo)
  //   // console.log("====>>proyectoInfo: ", proyectoInfo)

  //   return proyectoInfo
  // }, [proyectosGanados])
  const findProyectoGanado = (proyectosGanados: any[], proyectoId: any) => {
    const proyectoInfo = proyectosGanados.find(data => data.uid === proyectoId)
    setProyectoNow(proyectoInfo)
    return proyectoInfo
  }

  useEffect(() => {
    const newCotizacionesList = proyectosServer.filter((items) => {
      return items.status === 'ganado' && !items.terminado && { label: items.name, uid: items.uid }
    });
    //console.log("+ProyectosGanados+>> newCotizacionesList: ", newCotizacionesList)
    const newProyectosOptions = newCotizacionesList.map(({ name, uid }) => ({ label: name, value: uid }))
    setProyectosGanados(newCotizacionesList)
    setProyectosGanadosOptions(newProyectosOptions)

    // Modo: Opcion Editar
    if (typeAction?.data) {
      const { proyecto, rubro, rubroLabel } = typeAction.data
      //console.log("CARGANDO - proyecto: ", proyecto)
      const proyectoInfo = findProyectoGanado(newCotizacionesList, proyecto) // getCurrentProyecto(proyecto) // proyectosGanados.find(data => data.uid === proyecto)
      // setProyectoNow(proyectoInfo) ////
      const rubrosOptions = getRubrosOptions(proyectoInfo)
      const aux = rubrosOptions || []
      
      console.log("rubrosOptions >>> ", rubrosOptions)

      setRubrosOptions([...aux, { value: rubro, label: rubroLabel }])
    }
  }, [proyectosServer, typeAction.data]) // getCurrentProyecto - NOTA:OCUPO REVISAR PORQUE AL LLAMAR AL METODO SE CICLA

  useEffect(() => {
    console.log("[424] - cat!!")
    setOptionsCategorias(optionsCategoriasServer)
  }, [optionsCategoriasServer, setOptionsCategorias])

  const showAlert = (data: any) => {
    setAlert(data)

    if (data?.type === "success") {
      setTimeout(() => {
        setAlert(null)
        setShowModal(!showModal)
        handleSave()
      },1500 )
    }
  }

  const handleSaveData = async() => {
    // if (!isMoneda(formValue.monto)) {
    //   console.log("format > No es un formato - ", formValue.monto)
    //   return 
    // }

    try {
      setSaving(true)
      const { uid, transaccion, banco, categoria, subcategoria, fecha, rubro, monto } = formValue // proyecto, 

      const payload = {
        type: transaccion, // ingresos | egresos
        name: banco,
        uidMedio: subcategoria,
        uidCategoria: categoria,
        ...{ uid },
        detalles: { ...formValue, fecha: moment(fecha).format("X") },
      }


      if (uid ) { // on edit mode > it's need to delete
        const {transaccion: transaccionOrg, proyecto: proyectoOrg, banco: bancoOrg, monto: montoOrg } = typeAction.data
        const payloadDelete = {
          transaccion: transaccionOrg,
          proyecto: proyectoOrg,
          banco: bancoOrg,
          // bancoOrigen,
          monto: montoOrg,
          modo: "edit",
        }
        console.log("DELETE > dataOrg > ", payloadDelete)
        await transaccionDelete(payloadDelete, uid)
      }

      const response = await transaccionSave(payload)
//  console.log("++response > ", response)
//  console.log("==categoria: ", categoria)
      if (categoria === "1") {
        // console.log("DENSTRO DE-IF - transaccion: ", transaccion)
        if (transaccion === "ingresos") await updateProyectoIngreso(response?.uid || "", formValue.monto, proyectoNow) 
        else await updateProyectoEgreso(response?.uid || "", rubro, monto, proyectoNow)
      }

      showAlert({
        title: "Mensaje", message: "Datos Guardados...", type: "success",
      })
    } catch (e) {
      console.log("error > ", e)
      setSaving(false)
    }
  }

  useEffect(() => {
    if (!typeAction?.data) {
      return;
    }
    const { transaccion } = typeAction.data
    setIsValidateFine(true)
    setRequiredStatus({
      descripcion: true,
      categoria: true,
      subcategoria: true,
      transaccion: true,
      fecha: true,
      banco: true,
      monto: true,
    })
    
    const categorias = getCategorias(transaccion, optionsCategoriasServer)
    setOptionsCategorias(categorias)
    
  }, [optionsCategoriasServer, typeAction.data])

  // useEffect(() => {
  //   console.log("[451] typeAction.data.categoria: ", typeAction.data.categoria)
  // }, [typeAction.data.categoria])

  const onChange = (e: any) => {
    const { name, value } = e.target

    setFormValue({ ...formValue, [name]: value });
  }

  const onChangeFormatNumber = (e: any, name: string) => {
    const { value } = e

    setFormValue({ ...formValue, [name]: value });
  }

  const isValidate = (e: any) => {
    validateRequiredData(e, setRequiredStatus, setIsValidateFine)
  }

  const getRubrosOptions = (proyectoInfo: any) => {
    return proyectoInfo?.items
  .filter((item: any) => item.requisiciones && item.requisiciones.length > 0)
  .map((item: any) => {
    const requisicionesProcesoPago = (item.requisiciones || [])
      .filter((requisicion: any) => {
        const statusRequisicion = requisicion.statusRequisicion || [];
        const procesoPagoIndex = statusRequisicion.findIndex((status: any) => status.status === "proceso_pago");

        // Solo permitir requisiciones con estado "proceso_pago" y asegurarse de que sea el último estado en el array
        return procesoPagoIndex !== -1 && procesoPagoIndex === statusRequisicion.length - 1;
      })
      .map((requisicion: any) => {
        const { proveedorLabel, medioLabel, conceptoLabel, idRequisicion, cantidadRequisicion, costoRequisicion, repeticion } = requisicion;
        console.log("REQ > ", requisicion);
        return {
          label: `${proveedorLabel}(${medioLabel}>${conceptoLabel})`,
          value: idRequisicion,
          costo: getCosto({ cantidadRequisicion, costoRequisicion, repeticion }),
        };
      });

    return requisicionesProcesoPago;
  })
  .flat()
  }

  const onChangeSelect = (data: any) => {
    const {required, name, value, label} = data
    let extra = {}
console.log("onChangeSelect > data: ", data)
    if (name === "transaccion") {
      const categorias = getCategorias(value, optionsCategoriasServer)
      extra = {...startInformation, categoria: "", categoriaLabel: "" }
      setOptionsCategorias(categorias)
    } else if (name === "categoria") {
      extra = {...startInformation }
      setDetallesInformation(0)
    } else if (name === "proyecto") {
      const proyectoInfo = findProyectoGanado(proyectosGanados, value) // getCurrentProyecto(value)
      if(formValue.transaccion === "egresos") {
        const rubrosOptions = getRubrosOptions(proyectoInfo)
      
        console.log("rubrosOptions >>> ", rubrosOptions)
        setRubrosOptions(rubrosOptions || [])
      } else {
        const {pagos = []} = proyectoInfo
        console.log("proyectoInfo > ", pagos) 
        const montoTotal = pagos.reduce((total: any, item: any) => total + parseFloat(item.monto), 0);
        const montoResta = proyectoInfo.totalVenta - montoTotal

        console.log("montoTotal > ", montoTotal)
        setDetallesInformation(montoResta)
      }

      extra = { rubro: "", rubroLabel: "" }
    } else if(name === "rubro") {
      setDetallesInformation(data.dataObject.costo)
      extra = { monto: data.dataObject.costo }
    }

    setFormValue({ ...formValue, ...extra, [name]: value, [`${name}Label`]: label });

    isValidate({ target: { name, value, required }})
  }

  const setStartDate = (date: any) => {
    setFormValue({...formValue, fecha: date})
  }

  useEffect(() => {
    if (!formValue.categoria) return;

    const cat = optionsCategorias.find(({ value }) => value === formValue.categoria )

    setOptionsSubcategorias(cat?.subcategorias || [])

  }, [formValue.categoria, optionsCategorias])

  useEffect(() => {
    if (formValue.categoria === "1" && !formValue.subcategoria) {
      isValidate({ target: { name: "subcategoria", value: "1", required: "true" }})
      setFormValue({...formValue, subcategoria: "1", subcategoriaLabel: "CAMPANA"})
    }
  }, [formValue])

  const reviewSaveButtonStatus = () => {
    const { transaccion, subcategoria, proyecto, rubro } = formValue

    return !(isValidateFine && (
      transaccion === "ingresos" || 
      (transaccion === "egresos" && subcategoria !== "1") ||
      (transaccion === "egresos" && subcategoria === "1" && proyecto && rubro) ))
  }

  const constentForm = () => {
    const { descripcion, transaccion, proyecto, categoria, subcategoria, fecha, banco, monto, rubro, comentario } = formValue

    // console.log("+ formValue: ", formValue)

    return (
      <StyledContainerConcepto className="row" style={{padding: "20px"}} isValidated>
        <MDBRow className='mb-3'>
          <MDBCol md='6' className="px-2">
            <SelectCustom options={optionsTransacciones} onChangeSelect={onChangeSelect} name='transaccion' defaultValue={transaccion} required />
          </MDBCol>
          <StyledMDBCol md='6'>
            <DatePicker 
              className="form-control active" 
              selected={fecha} 
              onChange={setStartDate}  
              onBlur={isValidate}  
              customInput={
              <input
                readOnly
                onClick={(e) => e.preventDefault() }
                placeholder="Selecciona una fecha"
              />
            }
            />
          </StyledMDBCol>
          <MDBCol md='12' className="px-2">
            <MDBInput label="Descripcion" type='text' name='descripcion' value={descripcion} onChange={onChange} onBlur={isValidate} required />
          </MDBCol>          
          <MDBCol md='6' className="px-2 mb-3">
            <SelectCustom options={optionsCategorias} onChangeSelect={onChangeSelect} name='categoria' defaultValue={categoria} required />
          </MDBCol>
          <MDBCol md='6' className="px-2 mb-3">
            <SelectCustom options={optionsSubcategorias} onChangeSelect={onChangeSelect} name='subcategoria' defaultValue={ optionsSubcategorias.length ? subcategoria : ""} required />
          </MDBCol>
          { subcategoria === "1" && 
            <>
              <MDBCol md={ transaccion === "egresos" ? '6': '12'} className="px-2 mb-3">
                <SelectCustom options={proyectosGanadosOptions} onChangeSelect={onChangeSelect} name='proyecto' aria-describedby='txtProyect' defaultValue={proyecto} required />
                { transaccion === "ingresos" && <div id='txtProyect' className='form-text'><strong>{ `Monto pendiente por cobrar: $${detallesInformation}` }</strong></div> }
              </MDBCol>
              { transaccion === "egresos" && <MDBCol md='6' className="px-2 mb-3">
                <SelectCustom options={rubrosOptions} onChangeSelect={onChangeSelect} name='rubro' defaultValue={rubro} required />
                <div id='txtProyect' className='form-text'><strong>{ `Monto por pagar: ${formatMoney(detallesInformation)}` }</strong></div>
              </MDBCol>}
            </>  
          }
          <MDBCol md='6' className="px-2 mb-3">
            <SelectCustom options={optionsBancos} onChangeSelect={onChangeSelect} name='banco' defaultValue={banco} required />
          </MDBCol>
          <MDBCol md='6' className="px-2 mb-3">
            {/* <MDBInput label="Monto" type='text' name='monto' value={monto} onChange={onChange} onBlur={isValidate} required disabled={transaccion === "egresos" && detallesInformation !== 0} /> */}
            <NumericFormatCustom 
              name="monto"
              required={true}
              value={monto} 
              handleOnBlur={isValidate}
              handleOnChange={onChangeFormatNumber}
            />
          </MDBCol>

        </MDBRow>
        <MDBRow className='mt-1 px-2 mb-3'>
            <MDBTextArea 
              label='Comentarios' 
              name='comentario' 
              value={comentario} 
              onChange={onChange} rows={3}
              />
          </MDBRow>        
      </StyledContainerConcepto>
    )
  }
  // console.log("++ Transaccion: ", formValue.transaccion)
  // console.log("++ proyecto: ", formValue.proyecto)
  return (
    <AddItemModal
      keyModal="modal-lineadenegocio"
      alert={alert}
      typeAction={typeAction}
      showModal={showModal}
      setShowModal={setShowModal}
      handleSave={handleSaveData}
      disabledSaveButton={reviewSaveButtonStatus()}
      saving={saving}
    >
      { constentForm() }
    </AddItemModal>
  )
}

const Transacciones = () => {
  const transaccionesServer = useSettingsStore((state) => (state.transacciones), shallow)
  const { getTransaccionesServer } = useSettingsStore()
  const {getBancosServer} = useSettingsStore()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showImportarModal, setShowImportarModal] = useState(false)
  const [showModalTransferencia, setShowModalTransferencia] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [typeAction, setTypeAction] = useState({ typeName: "", option: "", data: { uid: "", transaccion: "", proyecto: "", banco: "", bancoOrigen: "", monto: "" }})
  const [typeActionImportar, setTypeActionImportar] = useState({typeName: ""});
  const [transacciones, setTransacciones] = useState<ITransaccion[]>([])

  const actionsDataTable = (data: any) => {
    // console.log("EDITAR > data: ", data)
    return (
      <div style={{ display: 'inline', flexDirection: 'row',
        flexWrap: 'nowrap',
        position: 'absolute',
        top: '15px' }} >
        <MDBBtn tag='a' color='none' className='m-1' 
          style={{ color: '#3b5998', width: '30px', height: '30px', overflow: 'initial' }} 
          onClick={() => {
            const type = data.transaccion === "transferencia" ? "transferencia" : "transaccion"
            console.log("+++ Edit > type: ", type)
            handlItemAction(type, "Editar", data)
          } } >
          <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>
  
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998', width: '30px', height: '30px' }} onClick={() => handlItemAction("Transacion", "Delete", data)} >
          <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </div>
    )
  }
  
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Descripcion",
      cell: (row) => {
        const { descripcion } = row
        return ( 
          <StyledToolTip>
            <MDBTooltip tag='a' placement='bottom' wrapperProps={{ href: '#' }} title={descripcion}>
              {descripcion}
            </MDBTooltip>
          </StyledToolTip>
        )
      },
      sortable: true,
      maxWidth: "200px",
    }, 
    {
      name: "Categoria",
      selector: (row: { categoriaLabel: any; }) => row.categoriaLabel,
      sortable: true,
      maxWidth: "140px",
    }, 
    {
      name: "Subcategoria",
      selector: (row: { subcategoriaLabel: any; }) => row.subcategoriaLabel,
      sortable: true,
      maxWidth: "140px",
    }, 
    {
      name: "Banco",
      selector: (row: { bancoLabel: any; }) => row.bancoLabel,
      sortable: true,
      maxWidth: "130px",
    }, 
    {
      name: "Monto",
      // selector: (row: { monto: any; }) => formatMoney(row.monto),
      cell: (row: { monto: any; }) => {
        return (
          <NumericFormat displayType="text" value={row.monto} prefix="$" thousandSeparator="," decimalScale={2}  />
        )
      },
      sortable: true,
      maxWidth: "110px",
    },
    {
      name: "Fecha Cambio",
      selector: (row: { updatedAt: any; }) => moment(row.updatedAt * 1000).format("DD/MM/YYYY"), //(row.updatedAt),
      sortable: true,
      maxWidth: "130px",
    },
    {
      name: "F Transaccion",
      selector: (row: { fecha: any; }) => moment(row.fecha * 1000).format("DD/MM/YYYY"), //(row.updatedAt),
      sortable: true,
      maxWidth: "130px",
    },
    {
      name: "Transaccion",
      cell: (row: { transaccion: string; }) => {
        const { transaccion } = row
        const colorStyle: ButtonProps["color"] = getColorTransaccion(transaccion)
          
        return (<MDBBtn rounded style={{ padding: "5px 10px", fontSize: "11px" }} color={colorStyle}>{row.transaccion}</MDBBtn> )
      },
      sortable: true,
      maxWidth: "130px",
    }, 
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true
    }
  ];

  useEffect(() => {
    const getData = async() => {
      // console.log("GET - DATA !!!")
      getTransaccionesServer()
    }
    
    getData()
  }, [getTransaccionesServer])

  useEffect(() => {
    // const newCotizacionesList = proyectosServer.filter((items) => {
    //   return items.status !== 'perdido' 
    // })
    // setCotizacionesActivos(newCotizacionesList)
    // setCotizaciones(newCotizacionesList)
    setTransacciones(transaccionesServer)
  }, [transaccionesServer])

  const handleSave = async() => {
    getTransaccionesServer()
    getBancosServer()
  }

  const handlItemAction = (typeName: string, option: string, data?: any) => {
    setTypeAction({ typeName, option, data })

    if (option === "Delete") {
      setShowDeleteModal(true);
    } else {
      typeName === "transferencia" ? setShowModalTransferencia(true) : setShowModal(true);
    }
  }

  const handlImportarAction = () => {
    setTypeActionImportar({ typeName: "Importar Transacciones" })
    setShowImportarModal(true)

  }

  const resetData = () => {
    setTransacciones(transaccionesServer)
  }
  const setNewData = (data: any[]) => {
    setTransacciones(data)
  }

  const handleDeleteAction = async() => {
    // console.log("DELETE > typeAction: ", typeAction)
    const { transaccion, banco, bancoOrigen, monto, uid, proyecto } = typeAction.data
    const payload = {
      transaccion,
      proyecto,
      banco,
      bancoOrigen,
      monto,
      modo: "delete",
    }
// console.log("DELETE > data > ", payload)
    await transaccionDelete(payload, uid)

    handleSave()
  }

  const exportToExcel = () => {
    const data: IDataExcelItem[] = transacciones.map((item) => {
      return {
        descripcion: item.descripcion,
        categoria: item.categoriaLabel,
        subCategoria: item.subcategoriaLabel,   
        banco: item.bancoLabel,
        monto: `$${item.monto}`,
        fechaDeCambio: moment(item.updatedAt * 1000).format("DD/MM/YYYY"),
        fechaDeInicio: moment(parseInt(item.fecha) * 1000).format("DD/MM/YYYY"),
        transaccion: item.transaccion,
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    // Define los títulos de las columnas
    const headers = ["Descripcion", "Categoria", "Sub-Categoria", "Banco", "Monto", "Fecha-Cambio", "F-Transaccion"];

    // Establece los títulos en la hoja de cálculo
    headers.forEach((header, index) => {
      worksheet[String.fromCharCode(65 + index) + '1'] = { v: header, t: 's' }; // 'A1', 'B1', 'C1', ...
    });

    // Agrega los datos debajo de los títulos
    data.forEach((item: any, index: any) => {
      const rowIndex = index + 2; // Empieza desde la fila 2
      Object.keys(item).forEach((key, columnIndex) => {
        worksheet[String.fromCharCode(65 + columnIndex) + rowIndex] = { v: item[key], t: 's' }; // 'A2', 'B2', 'C2', ...
      });
    });
    // Establece el ancho de las columnas
    const wscols: XLSX.ColInfo[] = [
      { wch: 45 }, // Ancho de la primera columna
      { wch: 25 }, // Ancho de la segunda columna
      { wch: 30 }, // Ancho de la tercera columna
      { wch: 20 }, // Ancho de la tercera columna
      { wch: 10 }, // Ancho de la tercera columna
      { wch: 10 }, // Ancho de la tercera columna
      { wch: 15 }, // Ancho de la tercera columna
      // Añade más objetos { wch: ancho } para las columnas adicionales si es necesario
    ];
    worksheet['!cols'] = wscols;

    const workbook = {
      Sheets: { 'Sheet1': worksheet },
      SheetNames: ['Sheet1']
    };

    XLSX.writeFile(workbook, moment().format('DD-MM-YYYY') + '-transacciones.xlsx');
  };

  return (
    <MDBContainer fluid>
      <HeaderContainer title={`Transacciones`} ></HeaderContainer>
      
      <SearchContainer dataServer={transaccionesServer} optionsSelect={[...optionsTransacciones, { value: 'transferencia', label: "Transferencia" }]} setNewData={setNewData} resetData={resetData} textItem="descripcion" selectItem="transaccion" />

      <div className="d-flex justify-content-end" style={{ margin: "10px 35px" }}>
        <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlItemAction("transferencia", "Nuevo")} >+ Transferencia entre cuentas</MDBBtn>
        <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlItemAction("transaccion", "Nuevo")} >+ Agregar Transaccion</MDBBtn> 
        <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlImportarAction() } >+ Importar Transacciones</MDBBtn>   
        <MDBBtn rounded className='mx-2' color='info' onClick={exportToExcel} >Exportar</MDBBtn>   
      </div> 
      <div className="d-flex justify-content-start">
      {/* <a href="#" className="stretched-link" onClick={() => exportToExcel()}>Exportar Datos</a> */}
      </div>
      <StyledSection>
        <div className="d-flex">
          {/* Contenedor de la tabla */}
          <div className="flex-grow-1 w-75">
            <DataTableCustom keyDataTable="table-servicios" columns={columns} data={transacciones} sortFieldId={6} sortAsc={false} />
          </div>
          <BancosSaldos />
  
        </div>
      </StyledSection>
      {showModalTransferencia && <AddNewFormTransferencia showModal={showModalTransferencia} setShowModal={setShowModalTransferencia} typeAction={typeAction} handleSave={handleSave} ></AddNewFormTransferencia>}  
      {showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} ></AddNewForm>}
      {showDeleteModal && <ConfirmDeleteModal keyModal="modal-transaccion-delete" showModal={showDeleteModal} setShowModal={setShowDeleteModal} typeAction={typeAction} handleSubmitDelete={handleDeleteAction} customCallDelete={true} />}

      {showImportarModal && <AddImportarForm showModal={showImportarModal} setShowModal={setShowImportarModal} typeAction={typeActionImportar} handleSave={handleSave} ></AddImportarForm>}
    </MDBContainer>
  );
}

export default Transacciones;
