import React, { ReactNode } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBSpinner } from 'mdb-react-ui-kit'; // MDBContainer, MDBModalFooter
import AlertMessage from '../general/alertMessage';
import { ModalDialogProps } from 'mdb-react-ui-kit/dist/types/free/components/Modal/ModalDialog/types';

interface IProps {
  keyModal: string;
  alert?: any;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, id?: number, value?: string, data?: any };
  handleSave: any;
  centeredDialog?: boolean;
  children: ReactNode;
  disabledSaveButton?: boolean;
  saving?: boolean;
  labelSaveButton?: string;
  sizeModa?: ModalDialogProps["size"];
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ( {
  keyModal, 
  alert = null, 
  showModal = false, 
  setShowModal, 
  typeAction: { typeName="", option="", data={} }, 
  handleSave, 
  centeredDialog, 
  children, 
  disabledSaveButton, 
  saving=false,
  labelSaveButton, 
  sizeModa="lg",
}: IProps ) => {

  const toggleShow = () => setShowModal(!showModal);

  return (
      <MDBModal staticBackdrop show={showModal} setShow={setShowModal} tabIndex='-1' >
        <MDBModalDialog scrollable size={sizeModa || "lg"} centered={centeredDialog}>
          <MDBModalContent key={keyModal} style={{border: "thin solid #3b71ca" }}>
            <MDBModalHeader>
              <MDBModalTitle className="text-capitalize" >
                {`${option} ${typeName} ${data.year ? ` ${data.year}` : ''}`}
              </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={(toggleShow)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              { children }
            </MDBModalBody>
            <MDBModalFooter>
              {alert && <div className='w-100'><AlertMessage alert={alert}/></div> }
              <div>
                <MDBBtn color='secondary' onClick={toggleShow}>
                <span className='px-2'>Cerrar</span>
                </MDBBtn>
                <MDBBtn onClick={handleSave} disabled={disabledSaveButton} className='ms-2' >
                  {
                    saving 
                      ? <><MDBSpinner size='sm' role='status' tag='span' className='me-1' /> Cargando... </>
                      : <span className='px-2'>{ labelSaveButton ||  "Guardar"}</span>
                  }
                  
                </MDBBtn>
              </div>
            </MDBModalFooter> 
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

  )
}
